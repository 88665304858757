body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Armin Grotesk Normal';
  src:
  local('Armin Grotesk Normal'),
  local('Armin-Grotesk-Normal'),
  url('./fonts/W Type Foundry - Armin Grotesk Normal.otf') format('opentype');
}
:any-link { color: #276c9d };
